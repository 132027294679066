import React, { Component, ErrorInfo, ReactNode } from 'react';

type ErrorBoundaryProps = {
  children: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
  errorMessage: string;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, errorMessage: "" };
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true, errorMessage: "" };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // Здесь можно отправить информацию об ошибке на сервер для логирования.
    this.setState({
      ...this.state,
      errorMessage: error.message
    })
    console.error('Caught an error:', error, errorInfo);
  }

  render(): ReactNode {
    if (this.state.hasError) {
      // Вместо вывода ошибки можно отрендерить запасной UI.
      return (
        <>
          <h1>Error:</h1>
          <p>{this.state.errorMessage}</p>
        </>
      );
    }

    // Если ошибок нет, просто рендерим дочерние элементы.
    return this.props.children;
  }
}

export default ErrorBoundary;
