import React from 'react'
import car from '../../assets/images/car_broken.png';
import './styles.css'

type Props = {}

const NotFoundPage = (props: Props) => {
  return (
    <div className='container_screen_container notFoundPage'>
      <div className='card'>
        <div className="left">
          <h3>Page not found</h3>
          <h2>404 error</h2>
          <p>Наведитесь на QR код снова,<br />чтобы подключится</p>
        </div>
        <div className="right">
          <img src={car} alt="" />
        </div>
      </div>
    </div>
  )
}

export default NotFoundPage