import React from 'react'
import Timer from '../Timer'
import Switcher from '../Switcher'
import Joystic from '../Joystic'
import angle from '../../assets/icons/angle_white.svg'
import './styles.css'

type Props = {
  isSensor: boolean
}

const GameController = ({isSensor}: Props) => {

  const className = !isSensor ? "card card--red card__gameControls" : "card card__gameControls" ;

  return (
    <div className={className}>
      <div className="controls">
        <div className='about'>
          {!isSensor ? <img src={angle} alt="angle" /> : <p>Используйте джойстик<br/>для управления курсором</p>}
          {!isSensor && <h3>Вращайте ваше устройство<br/>для управления курсором!</h3>}
        </div>
        <Joystic mode={isSensor ? "sensor" : "hyro"} />
      </div>
    </div>
  )
}

export default GameController