import React from 'react'
import GameController from '../../components/GameController'
import Car from '../../components/Icons/Car'
import Switcher from '../../components/Switcher'
import Timer from '../../components/Timer'
import eyes from '../../assets/icons/eye.svg'
import eyes_big from '../../assets/icons/eyes_big.svg'
import UserCursor from './UserCursor'
import blackArrow from '../../assets/icons/arrow_top_black.png'

type Props = {
  isSensor: boolean
  changeMode: () => void
  cursorId: number
  isTablet: boolean
}

const Horizontal = ({isSensor, changeMode, cursorId, isTablet}: Props) => {
  
  return (
    <div className='container_screen_container gamePage'>
      <div className="body">
        <div className="left">
          <div className="card card__beforeStart card__beforeStart--started">
            <div className='card__beforeStart__text'>Игра началась!</div>
            <Timer isTablet={isTablet} />
          </div>
          <div className="card card__description">
            <h2>Найдите курсор<UserCursor cursorId={cursorId || 0} /><br />на экране и наведите его на <span className='changingText'>цветную машинку</span><Car /></h2>
          </div>
        </div>
        <div className="right">
          <div className='gameHint__top'>
            <div className="card card__hint">
              <img src={isTablet ? eyes_big : eyes} alt="eyes" />
              {isTablet && "Смотрите на экран"}
              {!isTablet && <img src={blackArrow} alt="blackArrow" />}
            </div>
            <div className="card card__changeMode">
              {isSensor ? <div className='card__changeMode__text'>Может посложнее?</div> : <div className='card__changeMode__text blackText'>Может посложнее?</div>}
              <Switcher isEnabled={!isSensor} onClick={changeMode} isTablet={isTablet} />
            </div>
          </div>
          <GameController isSensor={isSensor} />
        </div>
      </div>
    </div>
  )
}

export default Horizontal