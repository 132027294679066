
export function parseUrlParameters(name: string): string {
  const url = window.location.search;
  const params: { [key: string]: string } = {};

  // Получаем часть URL после знака вопроса
  const queryString = url.split('?')[1];

  if (queryString) {
    // Разбиваем строку по символу "&" для получения параметров
    const paramPairs = queryString.split('&');

    // Обрабатываем каждую пару параметр=значение
    paramPairs.forEach(pair => {
      const [key, value] = pair.split('=');
      // Декодируем значения, так как они могут быть URL-кодированными
      params[key] = decodeURIComponent(value);
    });
  }

  console.log(params);

  return params[name] || '';
}