import React from 'react'

type Props = {
  isEnabled?: boolean
  onClick?: () => void
  isTablet: boolean
}

const Switcher = ({onClick, isEnabled, isTablet}: Props) => {
  const bgc = !isEnabled ? "#ECEFF2" : "#64D43D"

  console.log({isTablet})

  return isTablet ? <Big onClick={onClick} isEnabled={isEnabled} bgc={bgc} /> : <Small onClick={onClick} isEnabled={isEnabled} bgc={bgc} />
}

type InnerProps = {
  isEnabled?: boolean
  onClick?: () => void
  bgc: string
}

const Big = ({onClick, isEnabled, bgc}: InnerProps) => {

  const circlePosition:React.CSSProperties = !isEnabled ? {
    transform: "translate(0px, 5px)"
  } : {
    transform: "translate(48px, 5px)"
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="118" height="75" viewBox="0 0 118 75" fill="none" onClick={onClick}>
    <path d="M4.91663 37.4999C4.91663 21.2916 18.1242 8.1521 34.4166 8.1521L83.5833 8.1521C99.8757 8.1521 113.083 21.2916 113.083 37.4999C113.083 53.7083 99.8757 66.8477 83.5833 66.8477H34.4166C18.1242 66.8477 4.91663 53.7083 4.91663 37.4999Z" fill={bgc}/>
    <path d="M8.83337 32.5C8.83337 18.0926 20.5735 6.41306 35.0556 6.41306V6.41306C49.5377 6.41306 61.2778 18.0926 61.2778 32.5V32.5C61.2778 46.9074 49.5377 58.587 35.0556 58.587V58.587C20.5735 58.587 8.83337 46.9074 8.83337 32.5V32.5Z" fill="white" style={circlePosition}/>
    <defs>
      <filter id="filter0_d_1274_3030" x="1.83337" y="5.41309" width="68.4445" height="68.1738" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="4"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1274_3030"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1274_3030" result="shape"/>
      </filter>
    </defs>
  </svg>
  )
}

const Small = ({onClick, isEnabled, bgc}:InnerProps) => {

  const circlePosition:React.CSSProperties = !isEnabled ? {
    transform: "translate(0,0)"
  } : {
    transform: "translate(21px,0)"
  }
  
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="59" height="41" viewBox="0 0 59 41" fill="none" onClick={onClick}>
      <path d="M6.29163 18.5001C6.29163 10.9362 12.4477 4.80444 20.0416 4.80444L42.9583 4.80444C50.5522 4.80444 56.7083 10.9362 56.7083 18.5001C56.7083 26.064 50.5522 32.1957 42.9583 32.1957H20.0416C12.4477 32.1957 6.29163 26.064 6.29163 18.5001Z" fill={bgc}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M20.0417 4.04346L42.9584 4.04346C50.9742 4.04346 57.4723 10.5159 57.4723 18.5C57.4723 26.4841 50.9742 32.9565 42.9584 32.9565H20.0417C12.0259 32.9565 5.52783 26.4841 5.52783 18.5C5.52783 10.5159 12.0259 4.04346 20.0417 4.04346ZM20.0417 4.80433C12.4478 4.80433 6.29172 10.9361 6.29172 18.5C6.29172 26.0639 12.4478 32.1956 20.0417 32.1956H42.9584C50.5523 32.1956 56.7084 26.0639 56.7084 18.5C56.7084 10.9361 50.5523 4.80433 42.9584 4.80433L20.0417 4.80433Z" fill="#E0E7ED"/>
      <path d="M8.58337 18.5001C8.58337 11.7766 14.0554 6.32617 20.8056 6.32617C27.5557 6.32617 33.0278 11.7766 33.0278 18.5001C33.0278 25.2236 27.5557 30.674 20.8056 30.674C14.0554 30.674 8.58337 25.2236 8.58337 18.5001Z" fill="white" style={circlePosition}/>
      <defs>
        <filter id="filter0_d_1292_525" x="0.583374" y="0.326172" width="40.4445" height="40.3479" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="2"/>
          <feGaussianBlur stdDeviation="4"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1292_525"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1292_525" result="shape"/>
        </filter>
      </defs>
    </svg>
  )
}

export default Switcher