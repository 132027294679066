import React from 'react'
import './styles.css'
import star_small from './assets/star_small.png'
import star_medium from './assets/star_medium.png'
import star_large from './assets/star_large.png'
import arc from './assets/arc.png'

type Props = {
  driverId: string
  isHappy: boolean
}

const Avatar = ({driverId, isHappy}: Props) => {
  const postfix = isHappy ? "Happy" : "idle"
  const [imagePath, setImagePath] = React.useState("");

  const getImageName = (id: string) => `Il_${id}_${postfix}.png`

  React.useEffect(() => {
    // Загрузка изображения динамически по имени файла
    import(`../../assets/images/avatars/${getImageName(driverId)}`)
      .then((image) => {
        setImagePath(image.default);
      })
      .catch((error) => {
        console.error('Ошибка при загрузке изображения:', error);
      });
  }, [driverId]);

  if(isHappy) {
    return (
      <div className="avatar">
        <img src={imagePath} alt="" className="avatar__photo avatar--happy" />
        <img src={arc} alt="" className="arc" />
        <img src={star_small} alt="" className="star star--small" />
        <img src={star_medium} alt="" className="star star--medium" />
        <img src={star_large} alt="" className="star star--large" />
      </div>
    )
  }

  return (
    <div className="avatar">
      <img src={imagePath} alt="" className="avatar__photo avatar--happy" />
    </div>
  )
}

export default Avatar