import React from 'react'
import './styles.css'
import Smartphone from './Smartphone'
import Tablet from './Tablet'

function StartPage() {
  const isSmartphone = window.innerWidth < 960

  if(isSmartphone) {
    return <Smartphone />
  }
  return <Tablet />
}

export default StartPage