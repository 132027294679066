import React from 'react'

type Props = {
  cursorId: number
}
const UserCursor = ({cursorId}: Props) => {
  const [imagePath, setImagePath] = React.useState("");

  const getImageName = (id: number) => `icon_${id}.png`

  React.useEffect(() => {
    // Загрузка изображения динамически по имени файла
    import(`../../assets/icons/${getImageName(cursorId)}`)
      .then((image) => {
        setImagePath(image.default);
      })
      .catch((error) => {
        console.error('Ошибка при загрузке изображения:', error);
      });
  }, [cursorId]);

  return <img className='cursor' src={imagePath} alt="" />
}

export default UserCursor