import React from 'react'

const ArrowRight = () => {
  return (
    <svg width="64" height="24" viewBox="0 0 64 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M52.5371 0.477287L63.4957 10.8477C64.1681 11.4841 64.1681 12.5159 63.4957 13.1523L52.5371 23.5227C51.8646 24.1591 50.7743 24.1591 50.1018 23.5227C49.4294 22.8863 49.4294 21.8546 50.1018 21.2182L58.1208 13.6296H1.72197C0.770954 13.6296 0 12.9 0 12C0 11.1 0.770954 10.3704 1.72197 10.3704H58.1208L50.1018 2.78183C49.4294 2.14545 49.4294 1.11367 50.1018 0.477287C50.7743 -0.159096 51.8646 -0.159096 52.5371 0.477287Z" />
    </svg>
  )
}

export default ArrowRight