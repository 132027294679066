import React from 'react'

type Props = {}

const Loader = (props: Props) => {
  return (
    <div className="loader">
      <p>Загружаем</p>
      <div className="loader__bar">
        <div className="loader__inner"></div>
        <div className="loader__background"></div>
      </div>
    </div>
  )
}

export default Loader