import React, { PropsWithChildren } from 'react';
import { useAdressesParams } from '../hooks/useAdressesParams';
import { useDispatch } from 'react-redux';
import { Pages, goToPage, setInformationContent } from '../redux/gameSlice';
import { STATION_ID } from '../constants';
import { parseUrlParameters } from '../hooks/useGetCookieParam';
interface IContext {
  connectToWebsocket: (isRestart?: boolean) => void
  sendToRoute: () => void
  sessionLengthText: string
  moveJoystic: (coordinates: [number, number]) => void
  driverId: string
  cursorId: number
  restart: () => void
}

let ws: WebSocket | null = null
let session_id = ""
let sessionTime: NodeJS.Timeout | null = null
let pingTime: NodeJS.Timeout | null = null
let isConnected: boolean = false;

const WebsocketContext = React.createContext({} as IContext);

const WebsocketProvider = ({ children }: PropsWithChildren<unknown>) => {

  const dispatch = useDispatch();
  const [sessionLengthText, setSessionLengthText] = React.useState("")
  const [findedDriver, setFindedDriver] = React.useState("")
  const [cursorId, setCursorId] = React.useState(0)

  const startGame = () => {
    dispatch(goToPage(Pages.game))
  }
  const endGame = () => {
    dispatch(goToPage(Pages.founded))
  }
  const disconnect = () => {
    dispatch(goToPage(Pages.sessionEnd))
    dispatch(setInformationContent({
      label: "Время истекло",
      title: "Сессия окончена",
      description: 'Сессия окончена, отсканируйте новый QR код, что бы продолжить',
    }))
  }
  const connectionError = () => {
    dispatch(goToPage(Pages.sessionEnd))
    dispatch(setInformationContent({
      label: "Упс что-то пошло не так",
      title: "Cоединение разорвано",
      description: 'Попробуйте перезагрузить страницу и проверьте подключение',
    }))
  }

  const { DOMAIN, sid } = useAdressesParams()

  const connectToWebsocket = (isRestart?: boolean) => {
    const authParams = `client_${sid}`

    if(isConnected) {
      restart()
      startGame()
      return
    }

    const wsConnection = new WebSocket(DOMAIN, authParams)
    // const wsConnection = new WebSocket('ws://localhost:4001', authParams)

    wsConnection.onopen = (event) => {
      console.log({ event, wsConnection });
      ws = wsConnection
    }

    let sessionLength = 0
    wsConnection.onmessage = (message) => {
      try {
        const payload = JSON.parse(message.data) || {}
        const { event } = payload
        switch (event) {
          case "register":
            sessionLength = payload.sessionLength
            setSession(payload.clientId, payload.sessionLength, payload.cursorId)
            isConnected = true
            startGame()
            pingTime = setInterval(pingHandler, 15000);
            const userId = parseUrlParameters('sid')
            if(userId !== STATION_ID) {
              sessionTime = setInterval(sessionTimer, 1000);
            }
            return
          case "finded":
            const { driverId: id } = payload
            setFindedDriver(id);
            console.log(`Вы нашли водителя ${id}`)
            endGame();
            return
          default:
            console.warn('Non typed message', payload)
            return
        }
      } catch (error) {
        console.error(error)
      }
    }

    wsConnection.onerror = () => {
      console.log("ERROR")
      delete_cookie('id')
      pingTime && clearInterval(pingTime)
      connectionError();
      isConnected = false
    }

    wsConnection.onclose = (event) => {
      console.log("CLOSE")
      delete_cookie('id')
      console.log(`${event.code}: ${event.reason}`)
      pingTime && clearInterval(pingTime)
      isConnected = false
      if (event.reason === "sessionEnd") {
        disconnect()
      } else {
        connectionError();
      }
    }

    function sessionTimer() {
      sessionLength = sessionLength - 1000
      if (sessionLength <= 0 && sessionTime) {
        clearInterval(sessionTime)
        ws?.close()
        disconnect()
        session_id = ""
        return
      }
      const timerString = millisToMinutesAndSeconds(sessionLength)
      setSessionLengthText(timerString)
    }

    function pingHandler() {
      ws?.send(JSON.stringify({
        event: "ping"
      }))
    }
  }

  const sendToRoute = () => {
    ws?.send(JSON.stringify({
      event: 'start',
      driverId: findedDriver,
      clientId: session_id
    }))
    dispatch(goToPage(Pages.final))
  }

  const moveJoystic = (coordinates: [number, number]) => {
    if(ws?.readyState === 1) {
      ws?.send(JSON.stringify({
        event: 'move',
        clientId: session_id,
        coordinates,
        cursorId,
      }))
    } else {
      connectionError();
    }
  }
  const restart = () => {
    if(ws?.readyState === 1) {
      ws?.send(JSON.stringify({
        event: 'restart',
        clientId: session_id,
        cursorId,
      }))
    } else {
      connectionError();
    }
  }

  function setSession(id: string, sessionLength: number, cursor: number, isStation?: boolean) {
    session_id = id
    setCursorId(cursor)
    document.cookie = `id=${id}; max-age=${sessionLength}`;
  }

  const value: IContext = {
    connectToWebsocket,
    sessionLengthText,
    sendToRoute,
    moveJoystic,
    driverId: findedDriver,
    cursorId,
    restart
  }

  return <WebsocketContext.Provider value={value}>{children}</WebsocketContext.Provider>;
};

export default WebsocketProvider

export const useWebsocketContext = () => React.useContext(WebsocketContext);

function delete_cookie(name: string) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

function millisToMinutesAndSeconds(millis: number): string {
  const minutes: number = Math.floor(millis / 60000);
  const seconds: string = ((millis % 60000) / 1000).toFixed(0).padStart(2, '0');
  return `${minutes}:${seconds}`;
}