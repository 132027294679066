import { useSelector } from 'react-redux';
import './App.css';
import StartPage from './pages/StartPage';
import { RootState } from './redux/store';
import { Pages } from './redux/gameSlice';
import GamePage from './pages/GamePage';
import WelcomePage from './pages/WelcomePage';
import FoundedPage from './pages/FoundedPage';
import FinalPage from './pages/FinalPage';
import NotFoundPage from './pages/NotFoundPage';
import InformationPage from './pages/InformationPage';
import useImagePreloader from './hooks/useImagePreloader';
import { IMAGES_ARR } from './assets/images';
import SessionEndPage from './pages/SessionEndPage';

function App() {
  const page = useSelector((state: RootState) => state.game.page);
  const {devicePixelRatio, innerWidth, innerHeight} = window

  useImagePreloader(IMAGES_ARR)

  switch (page) {
    case Pages.welcome:
      return <WelcomePage />
    case Pages.start:
      return <StartPage />
    case Pages.game:
      return <GamePage />
    case Pages.founded:
      return <FoundedPage />
    case Pages.final:
      return <FinalPage />
    case Pages.infoPage:
      return <InformationPage />
    case Pages.sessionEnd:
      return <SessionEndPage />
    default:
      return <NotFoundPage />
  }
}

export default App;
