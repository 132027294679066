import React from 'react'
import './styles.css'

function StartHeader() {

  return (
    <div className="header header--welcomePage">
    </div>
  )
}

export default StartHeader