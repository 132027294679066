import React, { useEffect, useRef } from 'react';


const DEAFULT_TIMER = 30000;

function useResettableTimer(callback: () => void, delay = DEAFULT_TIMER) {
  const timerIdRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // Сбросить предыдущий таймер при изменении колбэка или задержки
    if (timerIdRef.current) {
      clearTimeout(timerIdRef.current);
    }

    // Установить новый таймер с заданным колбэком и задержкой
    timerIdRef.current = setTimeout(callback, delay);

    // Очистить таймер при размонтировании компонента
    return () => {
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current);
      }
    };
  }, [callback, delay]);

  // Функция для ручного сброса таймера
  const resetTimer = () => {
    if (timerIdRef.current) {
      clearTimeout(timerIdRef.current);
    }
    timerIdRef.current = setTimeout(callback, delay);
  };

  return {resetTimer, disableTimer: () => {timerIdRef.current = null}};
}

export default useResettableTimer;
