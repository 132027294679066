import React from 'react'
import finish from '../../assets/icons/finish.svg'
import retry from '../../assets/images/retry_icon.svg'
import './informationStyles.css'
import { useDispatch } from 'react-redux'
import { goToPage, Pages } from '../../redux/gameSlice'

const StationSessionEnd = () => {

  const dispatch = useDispatch();
  const goToStart = () => {
    dispatch(goToPage(Pages.start))
  }
  return (
    <div className='container_screen_container stationSessionEndPage'>
      <div className="card">
        <div className="left">
          <h1>Время сессии истекло!</h1>
          <p>Не переживайте! Нажмите на кнопку ниже<br/>для перезагрузки приложения</p>
          <div className="btn" onClick={goToStart}>
            <span>Начать заново!</span>
            <img src={finish} alt="" className='flag' />
          </div>
        </div>
        <div className="right">
          <img src={retry} alt="" />
        </div>
      </div>
    </div>
  )
}

export default StationSessionEnd