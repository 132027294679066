import React from 'react'
import './styles.css'
import arrow from '../../assets/icons/route_arrow.svg'
import { useDispatch } from 'react-redux'
import { Pages, goToPage } from '../../redux/gameSlice'
import Avatar from '../../components/Avatar'
import { usePerson } from '../../hooks/usePerson'
import whellIcon from '../../assets/icons/whell.svg'
import { useWebsocketContext } from '../../providers/WebsocketProvider'
import useDeviceType from '../../hooks/useDeviceType'

type Props = {}

const FinalPage = (props: Props) => {

  const dispatch = useDispatch()
  const {orientation} = useDeviceType()
  const isHorizontal = orientation === "landscape" ;

  const { driverId } = useWebsocketContext()
  // const { restart} = useWebsocketContext()
  // const driverId = "A7"

  const {name, route, finalText} = usePerson(driverId)

  const repeatGame = () => {
    dispatch(goToPage(Pages.start))
  }

  return (
    <div className='container_screen_container finalPage'>
      <div className="left">
        <div className="card">
          <div className="info">
            <Avatar driverId={driverId} isHappy />
            <div className="description">
              <h2>Вы нашли {name}</h2>
              <p>{finalText}</p>
            </div>
          </div>
          <div className="route">
            <h3>Маршрут</h3>
            <div className="route__path">
              <div className="route__plate">{(route||[])[0]}</div>
              <img src={arrow} alt="" className='route__arrow' />
              <div className="route__plate">{(route||[])[1]}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="right">
        <div className="card card--btn" onClick={repeatGame}>
          <img src={whellIcon} alt="" />
          <span>
            {isHorizontal ? <>Сыграем<br/>еще?</> : <>Сыграем еще?</>}
          </span>
        </div>
      </div>
    </div>
  )
}

export default FinalPage