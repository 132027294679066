import React from 'react'
import eyes from '../../assets/icons/eye.svg'
import './styles.css'
import GameController from '../../components/GameController'
import Car from '../../components/Icons/Car'
import Switcher from '../../components/Switcher'
import Timer from '../../components/Timer'
import { useWebsocketContext } from '../../providers/WebsocketProvider'
import useDeviceType from '../../hooks/useDeviceType'
import UserCursor from './UserCursor'
import Horizontal from './Horizontal'

type Props = {}

const GamePage = (props: Props) => {

  const { cursorId, moveJoystic } = useWebsocketContext()
  const {orientation} = useDeviceType()
  const isHorizontalOrientation = orientation === "landscape"
  const [isSensor, setIsSensor] = React.useState(true)

  const isTablet = window.innerWidth >= 960;

  const changeMode = () => {
    setIsSensor(!isSensor)
  }

  React.useEffect(() => {
    moveJoystic([0, 0])
  }, [cursorId, moveJoystic])

  if(isHorizontalOrientation) {
    return <Horizontal changeMode={changeMode} isSensor={isSensor} cursorId={cursorId} isTablet={isTablet} />
  }

  return (
    <div className='container_screen_container gamePage'>
      <div className="body">
        <div className="left">
          <div className="card card__beforeStart card__beforeStart--started">
            <div className='card__beforeStart__text'>Игра началась!</div>
            <Timer isTablet={isTablet} />
          </div>
          <div className="card card__description">
            <h2>Найдите курсор<UserCursor cursorId={cursorId || 0} /><br />на экране и наведите его на <span className='changingText'>цветную машинку</span><Car /></h2>
          </div>
          <div className="card card__hint">
            <img src={eyes} alt="eyes" />Смотрите на экран
          </div>
          <div className="card card__changeMode">
            {!isSensor ? <div className='card__changeMode__text'>Может посложнее?</div> : <div className='card__changeMode__text blackText'>Может посложнее?</div>}
            <Switcher isEnabled={!isSensor} onClick={changeMode} isTablet={isTablet} />
          </div>
        </div>
        <div className="right">
          <GameController isSensor={isSensor} />
        </div>
      </div>
    </div>
  )
}
export default GamePage