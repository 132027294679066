import React from 'react'
import './styles.css'
import finish from '../../assets/icons/finish.svg'
import arrow from '../../assets/icons/route_arrow.svg'
import { useWebsocketContext } from '../../providers/WebsocketProvider'
import Avatar from '../../components/Avatar'
import { usePerson } from '../../hooks/usePerson'
import { useDispatch } from 'react-redux'
import { Pages, goToPage } from '../../redux/gameSlice'

type Props = {}

let intervalSend: null | NodeJS.Timer = null;
const FoundedPage = (props: Props) => {
  const dispatch = useDispatch();

  const { sendToRoute, driverId, moveJoystic } = useWebsocketContext()
  // const { moveJoystic } = useWebsocketContext()
  // const sendToRoute = () => dispatch(goToPage(Pages.final))
  // const driverId = "A7";

  React.useEffect(() => {
    intervalSend = setInterval(() => {
      console.log("Sent [0,0]")
      moveJoystic([0, 0])
    }, 1000)

    return () => {
      if(intervalSend) {
        clearInterval(intervalSend)
      }
    }
  }, [moveJoystic])


  const { name, description, route, time_after, time_before } = usePerson(driverId)

  return (
    <div className='container_screen_container foundedPage'>
      <div className="left">
        <div className="card card__user">
          <div className="info">
            <Avatar driverId={driverId} isHappy={false} />
            <div className="description">
              <h2>Вы нашли {name}</h2>
              <p>{description}</p>
            </div>
          </div>
          <div className="route">
            <h3>Маршрут</h3>
            <div className="route__path">
              <div className="route__plate">{(route || [])[0]}</div>
              <img src={arrow} alt="" className='route__arrow' />
              <div className="route__plate">{(route || [])[1]}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="right">
        <div className="card card--route">
          <p>Время раньше</p>
          <div className="time">{minutesToTime(time_before || 0)}</div>
        </div>
        <div className="card card--route">
          <p>Время по МСД</p>
          <div className="time">{minutesToTime(time_after || 0)}</div>
        </div>
        <div className="card card--btn" onClick={sendToRoute}>
          <div>Отправить в путь!</div>
          <img src={finish} alt="" className='flag' />
        </div>
      </div>
    </div>
  )
}

function minutesToTime(minutes: number) {
  if (typeof minutes !== 'number' || isNaN(minutes)) {
    return 'Некорректное значение';
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = remainingMinutes.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
}

export default FoundedPage