import React, { useState, useRef, useEffect } from 'react';
import './DraggableCircle.css'; // Создайте стили для компонента
import joy from '../../assets/icons/sensor_joy.png'
import center from '../../assets/icons/sensor_joy_center.png'
import { useWebsocketContext } from '../../providers/WebsocketProvider';
import useResettableTimer from '../../hooks/useResettableTimer';
import { useDispatch } from 'react-redux';
import { Pages, goToPage } from '../../redux/gameSlice';

let prevPosition: number[] = [];
const Sensor: React.FC = () => {
  const [position, setPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [scaledPosition, setScaledPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const innerCircleRef = useRef<HTMLImageElement>(null);

  const dispatch = useDispatch();
  const goToStart = () => {
    dispatch(goToPage(Pages.start))
  }

  const {disableTimer, resetTimer} = useResettableTimer(goToStart)

  useEffect(() => {
    return () => {
      disableTimer();
    }
  }, [])
  

  const {moveJoystic} = useWebsocketContext()

  useEffect(() => {
    const newPosition = [scaledPosition.x, scaledPosition.y]
    if(JSON.stringify(newPosition) !== JSON.stringify(prevPosition)) {
      moveJoystic([scaledPosition.x, scaledPosition.y])
      resetTimer();
      prevPosition = newPosition
    }
  }, [moveJoystic, scaledPosition])
  

  useEffect(() => {
    if(innerCircleRef)  {
      if(innerCircleRef.current) {
        if(innerCircleRef.current.parentElement) {
          // Получение размеров внутреннего и внешнего кругов
          const innerCircleRect = innerCircleRef?.current?.getBoundingClientRect();
          const outerCircleRect = innerCircleRef?.current?.parentElement?.getBoundingClientRect();
      
          const radius = innerCircleRect.width / 2;
          const outerRadius = outerCircleRect.width / 2;
      
          // Масштабирование текущих координат в диапазоне от [-1, -1] до [1, 1]
          const scaledX = position.x / (outerRadius - radius);
          const scaledY = position.y / (outerRadius - radius);
          setScaledPosition({ x: scaledX, y: scaledY });
        }
      }
    }
  }, [position]);

  useEffect(() => {
    return () => {
      handleDragEnd()
    }
  }, [])
  

  const handleDragStart = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if ('touches' in e) {
      document.addEventListener('touchmove', handleDrag);
      document.addEventListener('touchend', handleDragEnd);
    } else {
      document.addEventListener('mousemove', handleDrag);
      document.addEventListener('mouseup', handleDragEnd);
    }
  };

  const handleDrag = (e: MouseEvent | TouchEvent) => {
    if(innerCircleRef) {
      if(innerCircleRef.current) {
        const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
        const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;
    
        const innerCircleRect = innerCircleRef.current.getBoundingClientRect();
        const outerCircleRect = innerCircleRef.current.parentElement!.getBoundingClientRect();
    
        const radius = innerCircleRect?.width / 2;
        const outerRadius = outerCircleRect?.width / 2;
    
        const x = clientX - outerCircleRect?.left - outerRadius;
        const y = clientY - outerCircleRect?.top - outerRadius;
    
        // Ограничение перемещения внутреннего круга внутри внешнего
        const distance = Math.sqrt(x ** 2 + y ** 2);
        if (distance > outerRadius - radius) {
          const angle = Math.atan2(y, x);
          const limitedX = (outerRadius - radius) * Math.cos(angle);
          const limitedY = (outerRadius - radius) * Math.sin(angle);
          setPosition({ x: limitedX, y: limitedY });
        } else {
          setPosition({ x, y });
        }
      }
    }
  };

  const handleDragEnd = () => {
    document.removeEventListener('touchmove', handleDrag);
    document.removeEventListener('touchend', handleDragEnd);
    document.removeEventListener('mousemove', handleDrag);
    document.removeEventListener('mouseup', handleDragEnd);

    // Возвращение внутреннего круга в центр после завершения перемещения
    setPosition({ x: 0, y: 0 });
  };

  return (
    <div className='sensor_joy'>
      <img src={joy} alt="" />
      <div className="outer-circle">
        <img src={center} alt="" className="inner-circle"
          ref={innerCircleRef}
          style={{
            transform: `translate(${position.x}px, ${position.y}px)`,
          }}
          onMouseDown={handleDragStart}
          onTouchStart={handleDragStart}/>
      </div>
    </div>
  );
};

export default Sensor