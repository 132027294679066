export type IPeople = {
  id: string
  name: string
  route: [string, string]
  time_before: number
  time_after: number
  percent?: number
  car?: string
  description: string
  effect?: string
  finalText: string
}

export const PEOPLE: IPeople[] = [
  {
    id: "A1",
    name: "семью Волковых",
    route: ["Бирюлево Восточное", "парк Сокольники"],
    time_before: 64,
    time_after: 43,
    percent: 33,
    car: "Семейный кроссовер",
    description: "Семья Волковых собралась погулять в парке Сокольники. Предложить им поехать по МСД?",
    effect: "Бирюлево Восточное",
    finalText: "Они сэкономят 21 минуту в поездке и смогут больше времени провести в парке!"
  },
  {
    id: "A2",
    name: "Ваню",
    route: ["Бирюлево Западное", "Восточный вокзал"],
    time_before: 50,
    time_after: 34,
    percent: 32,
    car: "Такси",
    description: "Иван собрался в командировку в Нижний Новгород. Таксист везет его на Восточный вокзал. Предложить ему поехать по МСД?",
    effect: "Бирюлево Западное",
    finalText: "Для поездки на Восточный вокзал он сэкономит 16 минут!"
  },
  {
    id: "A3",
    name: "Колю",
    route: ["Северное Бутово", "Ботанический сад"],
    time_before: 97,
    time_after: 58,
    percent: 41,
    car: "Каршеринг",
    description: "Коле в школе задали задание собрать гербарий. И они с мамой отправились в Ботанический сад! Предложить им поехать по МСД?",
    effect: "Северное Бутово",
    finalText: "Коля вместе с мамой отправились в Ботанический сад. Они сэкономили 39 минут!"
  },
  {
    id: "A4",
    name: "Диму",
    route: ["Чертаново Южное", "Лесопарк Измайлово"],
    time_before: 58,
    time_after: 38,
    percent: 34,
    car: "Грузовой минивэн",
    description: "Курьер Дмитрий развозит заказы интернет-магазина. Его распределительный центр находится в Чертаново Южном, а следующий клиент - в Измайлово. Предложить ему поехать по МСД?",
    effect: "Чертаново Южное",
    finalText: "У Димы появится целых 20 свободных минут!"
  },
  {
    id: "A5",
    name: "Влада",
    route: ["Южное Бутово", "парк Сокольники"],
    time_before: 82,
    time_after: 51,
    percent: 38,
    car: "Легковой",
    description: "Владислав едет к друзьям на новоселье! Предложить ему поехать по МСД? ",
    effect: "Южное Бутово",
    finalText: "Он сэкономит аж 31 минуту! Кажется он приедет к друзьям раньше чем ожидалось.",
  },
  {
    id: "A6",
    name: "Мишу",
    route: ["Бескудниковский", "аэропорт Домодедово"],
    time_before: 80,
    time_after: 60,
    percent: 25,
    car: "Такси",
    description: "Блоггер Михаил летит в Дубай! Но для начала ему надо добраться до аэропорта Домодедово! Предложить ему поехать по МСД?",
    effect: "Бескудниковский",
    finalText: "Добираться до аэропорта станет еще быстрее на 20 минут!"
  },
  {
    id: "A7",
    name: "Анатолия",
    route: ["Головинский", "Домодедово"],
    time_before: 80,
    time_after: 60,
    percent: 25,
    car: "Дачник с прицепом",
    description: "Пенсионер Анатолий везет посадочный материал на дачу в Домодедовском районе. Предложить ему поехать по МСД? ",
    effect: "Головинский",
    finalText: "Время поездки сократится на 20 минут! "
  },
  {
    id: "A8",
    name: "Аню с мамой",
    route: ["Битца", "Лефортово"],
    time_before: 74,
    time_after: 36,
    percent: 52,
    car: "Каршеринг",
    description: "Аня с мамой возвращаются с занятий по конкуру в КСК \"Битца\" домой в Лефортово. Предложить им поехать по МСД?",
    effect: "Лефортово",
    finalText: "Они окажутся дома намного раньше чем ожидали. На целых 38 минут!"
  },
  {
    id: "A9",
    name: "имя персонажа А9",
    route: ["Битца", "Свиблово"],
    time_before: 93,
    time_after: 54,
    percent: 42,
    car: "машина персонажа А9",
    description: "описание персонажа А9",
    effect: "Свиблово",
    finalText: "",
  },
  {
    id: "A10",
    name: "имя персонажа А10",
    route: ["Бирюлевский дендропарк", "Соколиная гора"],
    time_before: 60,
    time_after: 40,
    percent: 33,
    car: "машина персонажа А10",
    description: "описание персонажа А10",
    effect: "Соколиная гора",
    finalText: ""
  },
  {
    id: "A11",
    name: "Съемочную группу",
    route: ["Орёл", "Останкино"],
    time_before: 361,
    time_after: 333,
    percent: 7.76,
    car: "Микроавтобус",
    description: "Съемочная группа из \"Останкино\" снимала сюжет в г.Орле. Надо срочно сдать материал в эфир, нельзя терять ни секунды. Предложить им поехать по МСД?",
    effect: "Останкино",
    finalText: "Поездка до площадки будет быстрее на 28 минут!"
  },
  {
    id: "A12",
    name: "Архитектора Эльдара",
    route: ["Коттеджный поселок \"Соколиная гора\"", "Отрадное"],
    time_before: 127,
    time_after: 119,
    percent: 6.45,
    car: "Старенькая иномарка с красивой аэрографией",
    description: "Молодой архитектор Эльдар получил первый заказ, ему надо спроектировать дачную беседку. Эльдару не терпится начать делать макет. Предложить ему поехать по МСД?",
    effect: "Отрадное",
    finalText: "Эльдар спешит домой, но кажется машину нужно побыстрее. Он сократил время на 8 минут!"
  },
  {
    id: "A13",
    name: "Сережу",
    route: ["Подольск", "Ростокино"],
    time_before: 55,
    time_after: 44,
    percent: 20,
    car: "Грузовое такси",
    description: "Сергей доехал на велосипеде аж до Подольска! Неплохой велопробег получился. Только сил на обратную дорогу не хватило, пришлось брать такси. Предложить им поехать по МСД?",
    effect: "Ростокино",
    finalText: "Поездка по МСД вышла быстрее на 11 минут!",
  },
  {
    id: "A14",
    name: "Археолога Олега",
    route: ["Село Покров II", "Текстильщики"],
    time_before: 64,
    time_after: 59,
    percent: 7.81,
    car: "Легковой",
    description: "Археолог Олег ездил в село Покров II, датированное первым тысячилетием. Он спешит рассказать о своих находках на конференции. Предложить ему поехать по МСД?",
    effect: "Текстильщики",
    finalText: "Поездка по МСД вышла быстрее на 5 минут"
  },
  {
    id: "B1",
    name: "Сергея",
    route: ["Богородское", "Аэропорт Шереметьево"],
    time_before: 62,
    time_after: 39,
    percent: 37,
    car: "Легковой",
    description: "Сергей - пилот Аэрофлота. И каждый его рабочий день начинается с дороги из дома в Богородском до аэропорта Шереметьево. Предложить ему поехать по МСД?",
    effect: "Богородское",
    finalText: "У Сергея сократилось время поездки на 23 минуты!"
  },
  {
    id: "B2",
    name: "семью Васильковых",
    route: ["Западное Дегунино", "аэропорт Домодедово"],
    time_before: 90,
    time_after: 70,
    percent: 22,
    car: "Такси",
    description: "Семья Васильковых из Западного Дегунино отправляется в отпуск! Такси везет их в аэропорт Домодедово. Предложить водителю поехать по МСД?",
    effect: "Западное Дегунино",
    finalText: "Поездка в Домодедово теперь быстрее на 23 минуты!"
  },
  {
    id: "B3",
    name: "Владимира",
    route: ["Ховрино", "Завод \"Москвич\""],
    time_before: 65,
    time_after: 37,
    percent: 43,
    car: "Легковой",
    description: "Владимир из Ховрино работает слесарем за заводе \"Москвич\". Раньше его путь на работу занимал больше часа! Предложить поехать по МСД?",
    effect: "Ховрино",
    finalText: "Теперь на работу доезжать стало еще быстрее! На целых 28 минут!"
  },
  {
    id: "B4",
    name: "курьера",
    route: ["Шереметьево", "Гольяново"],
    time_before: 64,
    time_after: 42,
    percent: 34,
    car: "Грузовой минивэн",
    description: "Курьер службы экспресс-доставки забрал посылки в аэропорту Шереметьево и доставляет их. Его следующий получатель в Гольяново. Предложить ему поехать по МСД?",
    effect: "Гольяново",
    finalText: "Поездка для курьеров по этому маршруту станет быстрее на 22 минуты!"
  },
  {
    id: "B5",
    name: "семью Дроздовых",
    route: ["Химки", "Измайлово"],
    time_before: 62,
    time_after: 41,
    percent: 34,
    car: "Семейный кроссовер",
    description: "Семья Дроздовых из Химок собирается провести день на природе в Измайловском лесопарке. Предложить им поехать по МСД?",
    effect: "Измайлово",
    finalText: "Семья доберется до Измайловского парка на 21 минуту быстрее!"
  },
  {
    id: "B6",
    name: "Женю и Пашу",
    route: ["Северный речной вокзал", "Кузьминки"],
    time_before: 45,
    time_after: 40,
    percent: 7,
    car: "Каршеринг",
    description: "Женя и Паша возвращаются домой в Кузьминки после концерта на корабле, прибывшего на Северный Речной вокзал. Предложить им поехать по МСД?",
    effect: "Кузьминки",
    finalText: "Дома они окажутся на 5 минут раньше!"
  },
  {
    id: "B7",
    name: "таксиста",
    route: ["Метрогородок", "Шереметьево"],
    time_before: 60,
    time_after: 38,
    percent: 37,
    car: "Такси",
    description: "Небольшой столичный район с названием Метрогородок — по иронии судьбы один из немногих, где метро вовсе нет. И добраться оттуда можно только на такси. Но теперь есть МСД!",
    effect: "Метрогородок",
    finalText: "Доезжать до дома станет еще быстрее! На целых 22 минуты!"
  },
  {
    id: "B8",
    name: "Ольгу",
    route: ["Северный речной вокзал", "Северное Измайлово"],
    time_before: 51,
    time_after: 25,
    percent: 51,
    car: "Такси",
    description: "Начинающая певица Ольга выступала на празднике. Что тут сказать, сорвала аплодисменты и... голос. Срочно надо лечиться. Дома. Чаем с ромашкой. Предложить ей поехать по МСД?",
    effect: "Северное Измайлово",
    finalText: "Скорей домой! Ольга будет дома на 26 минут быстрее!"
  },
  {
    id: "B9",
    name: "Крис и Марину",
    route: ["Санкт-Петербург", "Печатники"],
    time_before: 51,
    time_after: 25,
    percent: 51,
    car: "Такси",
    description: "Подружки Крис и Марина возвращаются из Питера. Отлично погуляли девчонки! Ну а теперь пора по домам: разобрать сувениры, смонтировать ролик. Хорошо, что они живут по соседству. Предложить им поехать по МСД?",
    effect: "Печатники",
    finalText: "Путь был долгим, но домой можно будет доехать быстрее на 3 часа!"
  },
  {
    id: "B10",
    name: "мужиков",
    route: ["Тверь", "Преображенское"],
    time_before: 206,
    time_after: 128,
    percent: 37.86,
    car: "УАЗ Патриот",
    description: "Мужики возвращаются с рыбалки на Волге. А жены-то уже волнуются. Не будем испытывать брак на прочность. Предложить им поехать по МСД?",
    effect: "Преображенское",
    finalText: "Мужики успеют заехать за цветами женам! Ведь их пусть сократится на 78 минут!"
  },
  {
    id: "B11",
    name: "Георгия",
    route: ["Шереметьево", "Тимирязевский"],
    time_before: 64,
    time_after: 36,
    percent: 37.86,
    car: "Представительский автомобиль",
    description: "Георгий - звезда сериалов. Он только прилетел со съемок - и уже мчится к новой возлюбленной? Как ему оторваться от папарацци и не раскрыть скандальный роман? Предложить ему поехать по МСД?",
    effect: "Тимирязевский",
    finalText: "Он неуловим! Путь Георгия будет быстрее на 28 минут!"
  },
  {
    id: "B12",
    name: "Стаса",
    route: ["Долгопрудный", "Царицыно"],
    time_before: 87,
    time_after: 77,
    car: "SUV",
    description: "У Стаса одна оперативная полиграфия в Долгопрудном, вторая в Царицыно. Вот и приходится мотаться с расходниками туда-сюда. \"Надо ли расширяться?\" - думает Стас. Почему нет! Маршруты между офисами стали еще доступнее. Предложить ему поехать по МСД?",
    effect: "Тимирязевский",
    finalText: "Поездки Стаса станут быстрее на 10 минут. Можно заехать за кофе!"
  },
  {
    id: "B13",
    name: "Мишу",
    route: ["Тверь", "Северное Чертаново"],
    time_before: 254,
    time_after: 180,
    description: "Менеджер Михаил работает в девелоперской компании. У них новый объект под Тверью. Строительство идет полным ходом! Довольный, но уставший, Михаил возвращается домой. Предложить ему поехать по МСД?",
    effect: "Северное Чертаново",
    finalText: "На целых 74 минуты сократиться время поездки.На что их потратит Михаил?"
  },
  {
    id: "B14",
    name: "Ингу",
    route: ["Сходня", "Чертаново Центральное"],
    time_before: 132,
    time_after: 110,
    description: "Инга живет в Чертаново, а вот стричься ездит в Химки! Девочки поймут, к хорошему парикмахеру - хоть на Северный Полюс. МКАД стоит. Предложить ей поехать по МСД?",
    effect: "Чертаново Центральное",
    finalText: "Ездить к любимому парикмахеру станет еще быстрее! На целых 22 минуты!"
  },
  {
    id: "B15",
    name: "Славу",
    route: ["Лосиная станция", "Южнопортовый"],
    time_before: 70,
    time_after: 65,
    description: "Юный Слава все знает про животных. Родители решили отвезти его на лосиную биостанцию: «А вдруг у нас растет будущий зоолог?». Предложить им поехать по МСД?",
    finalText: "Поездка до Лосиной станции станет быстрее на 5 минут!"
  },
  {
    id: "B16",
    name: "Дениса",
    route: ["ул.Зеленая", "Ярославский район"],
    time_before: 34,
    time_after: 21,
    description: "Денис - крутой звуковик. Ездил смотреть новую студию на Зеленой улице в Химках. Студия понравилась. Но как ездить туда из дома? Проверим: сможет ли Денис сэкономить время в дороге?Предложить ему поехать по МСД?",
    finalText: "Ездить на новую студию станет быстрее на 13 минут."
  },
  {
    id: "C1",
    name: "Алёну",
    route: ["Вешняки", "Восточный вокзал"],
    time_before: 30,
    time_after: 23,
    description: "Алена едет навестить своих родителей в Иваново. Такси везет ее на восточный вокзал. Предложить поехать по МСД?",
    finalText: "Ездить до родителей станет быстрее на 7 минут!"
  },
  {
    id: "C2",
    name: "школьников",
    route: ["Выхино-Жулебино", "ВДНХ"],
    time_before: 43,
    time_after: 33,
    description: "3й \"А\" класс едет на экскурсию на Останкинскую башню. Предложить поехать по МСД?",
    finalText: "Быстрее на экскурсию! Время поездки сократиться на 10 минут!"
  },
  {
    id: "C3",
    name: "Диму и Борю",
    route: ["Косино-Ухтомский", "Лосиный остров"],
    time_before: 36,
    time_after: 24,
    description: "Дима и Боря - туристы. Сегодня они отправляются на пеший маршрут по Лосиному острову. Предложить им поехать по МСД? Они сэкономят 12 минут.",
    finalText: "Доехать до Лосинового острова можно быстрее на 12 минут! "
  },
  {
    id: "C4",
    name: "семью Афанасьевых",
    route: ["Некрасовка", "Северный речной вокзал"],
    time_before: 36,
    time_after: 24,
    description: "Семья Афанасьевых ни разу не были на Северном Речном вокзале и сегодня наконец решили поехать прогуляться там. Предложить им поехать по МСД?",
    finalText: "Семья сможет добраться быстрее на 17 минут!"
  },
  {
    id: "C5",
    name: "Агату",
    route: ["Мячково", "Нижегородский"],
    time_before: 80,
    time_after: 71,
    description: "Агата - та еще экстремалка. На своем дрифт-мобиле она взяла первое место на соревнованиях в Мячково. Но кто хочет возвращаться на заряженной тачке по пробкам? Предложить ей поехать по МСД?",
    finalText: "Прокатиться Агата с ветерком. Пожелаем ей легкой дороги! Время поездки сократится на 9 минут!"
  },
  {
    id: "C6",
    name: "Ольгу",
    route: ["Владимир", "Рязанский"],
    time_before: 248,
    time_after: 180,
    description: "Ольга вышла замуж и переезжает в Москву. Поздравляем! Папин друг вызвался помочь с переездом.Предложить им поехать по МСД?",
    finalText: "Переезд дело непростое, но поездка по МСД сократить время в пути на целый час! "
  },
  {
    id: "C7",
    name: "Оксану",
    route: ["Орехово-Зуево", "Текстильщики"],
    time_before: 134,
    time_after: 124,
    description: "Оксана ездила к родителям в Орехово-Зуево. Чем так вкусно пахнет в машине? Аааа, это мамины пирожки! Мужу везет. Предложить ей поехать по МСД?",
    finalText: "Оксана попадёт домой быстрее на 10 минут!"
  },
  {
    id: "C8",
    name: "полицию",
    route: ["Балашиха", "Южнопортовый"],
    time_before: 65,
    time_after: 53,
    description: "Полиция преследовала злоумышленников аж до Балашихи! Преступникам не удалось скрыться. Как доставить бандитов в отделение? Предложить им поехать по МСД?",
    finalText: "Поездка по МСД станет быстрее для сотрудников полиции на 12 минут! "
  },
  {
    id: "C9",
    name: "Вячеслава",
    route: ["Реутов", "Коптево"],
    time_before: 74,
    time_after: 49,
    description: "Вячеслав - прораб. Он ездил за стройматериалами в Реутов. Теперь надо доставить покупки на объект. Предложить ему поехать по МСД?",
    finalText: "Вячеслав сможет добраться быстрее на 25 минут! "
  },
  {
    id: "C10",
    name: "Игоря",
    route: ["Казань", "Аэропорт"],
    time_before: 733,
    time_after: 722,
    description: "У Игоряна - уникальная машина. На крыше - палатка, внутри можно организовать целое спальное место. Он любит на ней ездить в путешествия. А, если устал, можно отдохнуть прямо в машине! Но дома все-таки лучше. Предложить ему поехать по МСД?",
    finalText: "Поездка будет долгой, но выезжать из дома будет быстрее на 10 минут! "
  },
  {
    id: "C11",
    name: "Наташу",
    route: ["Владимир", "Нижегородский"],
    time_before: 244,
    time_after: 183,
    description: "Наталья - гид в небольшой туристической фирмы. Сегодня она водила туристов по г.Владимиру. Пора возвращаться в Москву.  Предложить им поехать по МСД?",
    finalText: "Наталья мчится домой и окажется там на час раньше! Все благодаря МСД!"
  },
  {
    id: "C12",
    name: "Толю и Машу",
    route: ["Новая Купавна", "Хамовники"],
    time_before: 75,
    time_after: 67,
    description: "Толя и Маша - ведущие на мероприятий. Сегодня они вели корпоратив в конгресс-отеле в Новой Купавне. Все было на уровне! Только Маша стерла ноги в новых туфлях. Толя спешит довезти коллегу до дома. Предложить им поехать по МСД?",
    finalText: "Поездка домой будет быстрее на 8 минут!"
  },
  {
    id: "C13",
    name: "семью Петровых",
    route: ["Подмосковная усадьба Горенки", "Таганский"],
    time_before: 51,
    time_after: 40,
    description: "У Петровых - традиция, по выходным они всей семьей ездят по разным интересным местам за городом. Сегодня Петровы побывали в усадьбе Горенки. Предложить им поехать по МСД?",
    finalText: "Поездка до усадьбы Горенки станет быстрее на 11 минут!"
  },
  {
    id: "C14",
    name: "Кирилла",
    route: ["Новое Измайлово", "Головинский"],
    time_before: 56,
    time_after: 48,
    description: "У Кирилла родился племянник! Пришлось везти огромного плюшевого медведя аж в Новое Измайлово, что за МКАДом. \"Может и нам с Ритой пора?\" - думает Кирилл, пока едет в такси. Предложить ему поехать по МСД?",
    finalText: "Поездка в Новое Измайлово станет быстрее на 8 минут!"
  },
  {
    id: "C15",
    name: "медика Бориса",
    route: ["ФГБУ «НМХЦ им. Н.И. Пирогова»", "Коптево"],
    time_before: 69,
    time_after: 45,
    description: "Борис - молодой медик. Он ездил на стажировку в Центр имени Пирогова и с новыми знаниями возвращается в Москву, чтобы написать свою первую научную статью для интернета. Предложить ему поехать по МСД? ",
    finalText: "Добираться на работу Борису станет быстрее на 24 минуты! "
  },
  {
    id: "C16",
    name: "Катю и Диму",
    route: ["Лисья гора", "Якиманка"],
    time_before: 64,
    time_after: 55,
    description: "Катя и Дима обожают кататься на сноубордах! Довольные и счастливые они возвращаются с Лисьей горы, чтобы дома запланировать новые покатушки. Предложить им поехать по МСД?",
    finalText: "Доехать до Лисьей горы будет быстрее на 9 минут!"
  },
]