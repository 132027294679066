import React from 'react'
import car from '../../assets/images/car_broken.png';
import './styles.css'
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { parseUrlParameters } from '../../hooks/useGetCookieParam';
import { STATION_ID } from '../../constants';
import StationInformation from './StationInformation';

type Props = {}

const InformationPage = (props: Props) => {

  const {description, label, title} = useSelector((state: RootState) => state.game.information)

  const userId = parseUrlParameters('sid')
  if(userId === STATION_ID) {
    return <StationInformation />
  }

  return (
    <div className='container_screen_container informationPage'>
      <div className='card'>
        <div className="left">
          <h3>{label}</h3>
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
        <div className="right">
          <img src={car} alt="" />
        </div>
      </div>
    </div>
  )
}

export default InformationPage