import React from 'react'
import useDeviceType from '../../hooks/useDeviceType'
import PlayButton from '../../components/PlayButton'
import ArrowRight from '../../components/Icons/ArrowRight'
import arrow from '../../assets/icons/arrow.svg'
import car from '../../assets/icons/car.svg'
import image from '../../assets/images/welcome_tablet_image.png'
import { useWebsocketContext } from '../../providers/WebsocketProvider'

type Props = {}

const Tablet = (props: Props) => {

  const {orientation} = useDeviceType()
  const isHorizontalOrientation = orientation === "landscape" ;

  const {connectToWebsocket} = useWebsocketContext()

  
  return (
    <div className='container_screen_container startPage'>
      <div className="body">
        <div className="left">
          <div className="card">
            <div className='side'>
              <div className="imageContainer">
                <img src={image} alt="" className='bigImage'/>
              </div>
              <h2>Узнайте о <span>преимуществах</span> новых транспортных узлов!</h2>
              <div className="instructions">
                <div className="instructions__description">
                  <h3 className='startPage__title'>Сыграем?</h3>
                  <p>Перед вами интерактивная игра, в которой вам предстоить поймать цветные машинки на экране с помощью виртуального курсора.</p>
                </div>
                <div className="instructions__points">
                  <h3>Что надо делать?</h3>
                  <div className='list__item'><img src={arrow} alt="arrow" /><span>Искать цветные машинки<br />в городе!</span></div>
                  <div className='list__item'><img src={car} alt="car" /><span>Ловить цветные машинки на экране с помощью виртуального курсора</span></div>
                </div>
              </div>
            </div>
            {!isHorizontalOrientation && <div className='side'>
              <PlayButton clickHandler={connectToWebsocket}>
                <span>Играть</span>
                <ArrowRight />
              </PlayButton>
            </div>}
          </div>
        </div>
        {isHorizontalOrientation && <div className="right">
          
          <PlayButton clickHandler={connectToWebsocket}>
            <span>Играть</span>
            <ArrowRight />
          </PlayButton>
        </div>}
      </div>
    </div>
  )
}

export default Tablet