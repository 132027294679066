import { useEffect, useState } from 'react';
import { isMobile, isTablet, useMobileOrientation } from 'react-device-detect';

type DeviceType = "smartphone" | "tablet"

const useDeviceType = () => {
  const [deviceType, setDeviceType] = useState<DeviceType>('smartphone');
  const { orientation } = useMobileOrientation()

  useEffect(() => {
    if (isTablet) {
      setDeviceType('tablet');
    } else if (isMobile) {
      setDeviceType('smartphone');
    }
  }, []);

  return {deviceType, orientation};
};

export default useDeviceType;
