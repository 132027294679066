import React, { PropsWithChildren } from 'react'
import './styles.css'
import { useDispatch } from 'react-redux'
import { Pages, goToPage, setInformationContent } from '../../redux/gameSlice'

type Props = {
  clickHandler: () => void
}

const PlayButton= ({children, clickHandler}: PropsWithChildren<Props>) => {

  const dispatch = useDispatch();

  const requestOrientation = () => {
    clickHandler();
    // @ts-ignore
    if (window.DeviceMotionEvent && typeof DeviceMotionEvent.requestPermission === 'function') {
      // @ts-ignore
      DeviceMotionEvent.requestPermission()
        .then((response: string) => {
          // Обрабатываем ответ после запроса разрешения
          if (response === 'granted') {
            console.log("Доступ получен")
          } else {
              console.log("Доступ запрещён")
              dispatch(goToPage(Pages.infoPage))
              dispatch(setInformationContent({
                label: "нет доступа",
                title: "Доступ к гироскопу запрещён",
                description: 'Пожалуйста разрешите доступ к данным гироскопа что бы получить лучший опыт от игры',
              }))
          }
        })
        .catch((e: any) => {
          console.error(e)
        });
    }    
  }

  return (
    <button className='btn' onClick={requestOrientation}>{children}</button>
  )
}

export default PlayButton