import star_small from '../components/Avatar/assets/star_small.png'
import star_medium from '../components/Avatar/assets/star_medium.png'
import star_large from '../components/Avatar/assets/star_large.png'
import arc from '../components/Avatar/assets/arc.png'
import angle from './icons/angle_white.svg'
import joystic from './icons/joy_base.png'
import center from './icons/joy_base_center.png'
import joy_sensor from './icons/sensor_joy.png'
import center_sensor from './icons/sensor_joy_center.png'
import arrow from './icons/route_arrow.svg'
import whellIcon from './icons/whell.png'
import finish from './icons/finish.svg'
import eyes from './icons/eye.svg'
import eyes_big from './icons/eyes_big.svg'
import blackArrow from './icons/arrow_top_black.png'
import car from './images/car_broken.png';
import car_icon from './icons/car.svg'

export const IMAGES_ARR = [
  star_small,
  star_medium,
  star_large,
  arc,
  angle,
  joystic,
  center,
  joy_sensor,
  center_sensor,
  arrow,
  whellIcon,
  finish,
  eyes,
  eyes_big,
  blackArrow,
  car,
  car_icon
]