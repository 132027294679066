import React from 'react'

function StartPageHeader() {
  
  return (
    <div className="header">
    </div>
  )
}

export default StartPageHeader