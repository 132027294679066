import React from 'react'
import PlayButton from '../../components/PlayButton'
import arrow from '../../assets/icons/arrow.svg'
import car from '../../assets/icons/car.svg'
import ArrowRight from '../../components/Icons/ArrowRight'
import StartPageHeader from './Header'
import useDeviceType from '../../hooks/useDeviceType'
import { useWebsocketContext } from '../../providers/WebsocketProvider'

function Smartphone() {
  const { orientation } = useDeviceType()

  const {connectToWebsocket} = useWebsocketContext()

  const description = React.useMemo(() => {
    if (orientation === "landscape") {
      return {
        topText: <p>Перед вами интерактивная игра, в которой вам предстоит поймать цветные машинки на экране с помощью виртуального курсора.</p>,
        bottomText: <p>Ловить цветные машинки на экране с помощью виртуального курсора</p>
      }
    }
    return {
      topText: <p>Перед вами интерактивная игра, в которой вам предстоит поймать цветные машинки на экране с помощью виртуального курсора.</p>,
      bottomText: <p>Ловить цветные машинки<br />на экране с помощью<br />виртуального курсора</p>
    }
  }, [orientation])

  return (
    <div className='container_screen_container startPage'>
      <StartPageHeader />
      <div className="body">
        <div className="left">
          <div className="card">
            <h2>Узнайте о <span>преимуществах</span> новых транспортных узлов!</h2>
            <h3 className='startPage__title'>Сыграем?</h3>
            {description.topText}
            {!(orientation === "landscape") && <>
              <h3 className='startPage__subtitle'>Что надо делать?</h3>
              <div className='startPage__points'>
                <img src={car} alt="car" />
                <p>Искать цветные машинки<br />в городе!</p>
              </div>
              <div className='startPage__points'>
                <img src={arrow} alt="arrow" />
                <p>Ловить цветные машинки<br />на экране с помощью<br />виртуального курсора</p>
              </div>
            </>}
          </div>
        </div>
        {(orientation === "landscape") && <div className="right">
          <div className="card">
            <h3>Что надо делать?</h3>
            <div className='list__item'><img src={car} alt="car" />Искать цветные машинки в городе!</div>
            <div className='list__item'><img src={arrow} alt="arrow" />{description.bottomText}</div>
          </div>
          <PlayButton clickHandler={connectToWebsocket}>
            <span>Играть</span>
            <ArrowRight />
          </PlayButton>
        </div>}
        {!(orientation === "landscape") && <PlayButton clickHandler={connectToWebsocket}>
          <span>Играть</span>
          <ArrowRight />
        </PlayButton>}
      </div>
    </div>
  )
}

export default Smartphone