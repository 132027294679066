import React from 'react'
import './styles.css'
import Hyro from './Hyro'
import Sensor from './Sensor'

type Props = {
  mode: "hyro" | "sensor"
}

const Joystic = ({mode}: Props) => {
  if(mode === "sensor") {
    return <Sensor />
  }
  return <Hyro />
}

export default Joystic