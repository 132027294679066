export const useAdressesParams = () => {
  let serverId = getServer();
  const DOMAIN = `wss://${window.location.host}/websocket${serverId || ""}`
  const sid = getSid()
  return { DOMAIN, sid }
}

function getSid() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get('sid')
}
function getServer() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get('websocket')
}