import React from 'react'
import './styles.css'
import { parseUrlParameters } from '../../hooks/useGetCookieParam';
import { STATION_ID } from '../../constants';
import StationSessionEnd from './StationInformation';
import retry from '../../assets/images/retry_icon.svg'

type Props = {}

const SessionEndPage = (props: Props) => {

  const userId = parseUrlParameters('sid')
  if(userId === STATION_ID) {
    return <StationSessionEnd />
  }

  return (
    <div className='container_screen_container sessonEndPage'>
      <div className='card'>
        <div className="left">
          <h1>Время сессии<br/>истекло</h1>
          <p>Не переживайте! Наведитесь на QR код снова, чтобы зайти в приложение.</p>
        </div>
        <div className="right">
          <img src={retry} alt="" />
        </div>
      </div>
    </div>
  )
}

export default SessionEndPage