import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export enum Pages {
  welcome = "welcome",
  start = "start",
  game = "game",
  founded = "founded",
  final = "final",
  notFound = "notFound",
  sessionEnd = "sessionEnd",
  infoPage = "infoPage",
}

export interface GameState {
  page: Pages
  information: InformationContent
}

export interface InformationContent {
  label: string
  title: string
  description: string
}

const initialState: GameState = {
  page: Pages.welcome,
  information: {
    label: "",
    title: "string",
    description: "string"
  }
}

export const gameSlice = createSlice({
  name: 'gameSlice',
  initialState,
  reducers: {
    goToPage: (state, action: PayloadAction<Pages>) => {
      state.page = action.payload
    },
    setInformationContent: (state, action: PayloadAction<InformationContent>) => {
      state.information = action.payload
    }
  },
})

export const { goToPage, setInformationContent } = gameSlice.actions

export default gameSlice.reducer