import React, { useEffect } from 'react'
import joystic from '../../assets/icons/joy_base.svg'
import center from '../../assets/icons/joy_base_center.svg'
import { useWebsocketContext } from '../../providers/WebsocketProvider'
import useDeviceType from '../../hooks/useDeviceType'
import { useDispatch } from 'react-redux'
import useResettableTimer from '../../hooks/useResettableTimer'
import { goToPage, Pages } from '../../redux/gameSlice'

type Props = {}
const TRESHOLD = 15
const Hyro = (props: Props) => {

  const outerContainer = React.useRef(null)
  const innerElement = React.useRef(null)
  const { moveJoystic } = useWebsocketContext()

  const dispatch = useDispatch();
  const goToStart = () => {
    dispatch(goToPage(Pages.start))
  }

  const {disableTimer, resetTimer} = useResettableTimer(goToStart)

  useEffect(() => {
    return () => {
      disableTimer();
    }
  }, [])

  const {orientation} = useDeviceType()

  React.useEffect(() => {
    window.addEventListener('deviceorientation', handleOrientation);

    return () => {
      window.removeEventListener('deviceorientation', handleOrientation);
    }
  }, [orientation, handleOrientation])

  function siceRotation(rotation: number) {
    if (rotation > TRESHOLD) {
      return TRESHOLD
    }
    if (rotation < -TRESHOLD) {
      return -TRESHOLD
    }
    return rotation
  }

  function round(num: number) {
    return Number((num).toFixed(2))
  }

  function handleOrientation(event: DeviceOrientationEvent) {
    const container: any = outerContainer.current
    const innerCircle: any = innerElement.current
    if (container) {
      let x = 0
      let y = 0
      if (orientation === "landscape") {
        x = event.beta ? siceRotation(event.beta) : 0;
        y = event.gamma ? siceRotation(event.gamma) : 0;
      } else {
        y = event.beta ? siceRotation(event.beta) : 0;
        x = event.gamma ? siceRotation(event.gamma) : 0;
      }
      var max = 50;
      var correction = max

      var posX = (x / 25) * correction;
      var posY = (y / 25) * correction;
      console.log(({ inner: container.clientWidth, outer: innerCircle.clientWidth }))
      console.log({ x, y })
      const coordinatesArr: [number, number] = [round(x / TRESHOLD), round(y / TRESHOLD)]
      console.log(`Coordinates for send: ${coordinatesArr}`)
      moveJoystic(coordinatesArr)
      resetTimer();
      innerCircle.style.transform = `translate(${posX}px, ${posY}px)`;
    }
  }

  return (
    <div className='joystic_container'>
      <img src={joystic} alt="" className='joystic_outer' />
      <div className="joystic_inner" ref={outerContainer}>
        <img src={center} alt="" ref={innerElement} className='innerJoy' />
      </div>
    </div>
  )
}

export default Hyro