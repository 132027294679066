import React from 'react'
import './styles.css'
import { useDispatch } from 'react-redux'
import { Pages, goToPage } from '../../redux/gameSlice'
import StartHeader from '../../components/StartHeader'
import Loader from './Loader'

type Props = {}

const WelcomePage = (props: Props) => {
  const dispatch = useDispatch()
  const nextPage = React.useCallback(() => {
    dispatch(goToPage(Pages.start))
  }, [dispatch])

  React.useEffect(() => {
    setTimeout(() => {
      nextPage()
    }, 2000);
  }, [nextPage])


  return (
    <div className='welcomePage' onClick={nextPage}>
      <StartHeader />
      <Loader />
    </div>
  )
}

export default WelcomePage